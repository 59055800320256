/** 发布卖品表单 */
<template>
  <div class="my-sell-form">
    <el-form ref="form" :model="form" :rules="rulesForm" hide-required-asterisk label-width="100px">
      <el-form-item v-if="!isEdit" label="专利情况">
        <el-select v-model="patent_way" placeholder="请选择专利情况" @change="wayChange">
          <el-option label="单独专利卖出" value="single"> </el-option>
          <el-option label="专利资产包卖出" value="pack"> </el-option>
        </el-select>
      </el-form-item>

      <template v-if="patent_way == 'pack'">
        <el-form-item prop="name" label="资产包名称">
          <span v-if="isAddPackPatent">{{ form.name }}</span>
          <el-input v-else v-model="form.name" placeholder="请输入资产包名称"></el-input>
        </el-form-item>

        <el-form-item prop="price" label="价格 (元)">
          <span v-if="isAddPackPatent">{{ form.price }}</span>
          <el-input v-else v-model.number="form.price" placeholder="不填写价格，默认显示面议"></el-input>
        </el-form-item>

        <el-form-item prop="path" label="资产包图片">
          <div v-if="isAddPackPatent" style="width: 200px;height: 200px;">
            <el-image :src="$base + form.path"></el-image>
          </div>
          <UploadImage v-else v-model="form.path" @changeUpload="changeUpload" />
        </el-form-item>

        <el-form-item prop="details" label="资产包描述">
          <span v-if="isAddPackPatent">{{ form.details }}</span>
          <el-input v-else type="textarea" v-model="form.details" placeholder="请输入资产包描述"></el-input>
        </el-form-item>

        <el-form-item v-if="form.state == 3" prop="reject_text" label="驳回原因">
          <span>{{ form.reject_text }}</span>
        </el-form-item>
      </template>

      <el-row>
        <el-col v-for="(item, index) in form.patent_data" :key="index">
          <el-divider v-if="patent_way == 'pack'"></el-divider>

          <el-form-item class="form-item-dynamic" :prop="'patent_data.' + index + '.patent_ano'"
            :label="'专利号' + (patent_way == 'pack' && !isAddPackPatent ? (index + 1) : '')"
            :rules="[{ required: true, message: '请输入专利号', trigger: 'blur' }]">
            <el-input v-model="item.patent_ano" :disabled="isEdit && !isAddPackPatent" placeholder="请输入专利号"></el-input>
            <el-button v-if="!isEdit || isAddPackPatent" type="primary" @click="seekClick(item, index)"
              :loading="item.loading">专利查询</el-button>
            <el-button v-if="patent_way == 'pack' && !isAddPackPatent" type="danger"
              @click="delBtn(item.id, index)">删除</el-button>
          </el-form-item>

          <el-form-item :prop="'patent_data.' + index + '.patent_info'" label="专利信息"
            :rules="[{ required: true, message: '请获取专利信息', trigger: 'blur' }]">
            <div v-if="item.patent_info && Object.keys(item.patent_info).length"
              class="sell-trading-info justify-between-center">
              <div>
                <p>专利权人：{{ item.patent_info.patentee }}</p>
                <p>专利类型：{{ item.patent_info.patent_type }}</p>
              </div>
              <el-link type="primary" @click="lookTradingInfo(item.patent_info)">调取成功，查看全部详情> </el-link>
            </div>
            <el-input v-else readonly placeholder="通过专利查询，获取专利信息"></el-input>
          </el-form-item>

          <el-form-item :prop="'patent_data.' + index + '.price'" label="专利价格 (元)"
            :rules="[{ validator: checkPrice, trigger: 'blur' }]">
            <el-input v-model.number="item.price" placeholder="不填写价格，默认显示面议"></el-input>
          </el-form-item>

          <el-form-item prop="feat_type" label="合作类型">
            <el-select v-model="item.feat_type" placeholder="请选择合作类型">
              <el-option label="转让" value="转让"> </el-option>
              <el-option label="许可" value="许可"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="item.state == 3" prop="reject_text" label="驳回原因">
            <el-input type="textarea" v-model="item.reject_text" resize="none" :autosize="{ minRows: 1, maxRows: 3 }"
              readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item class="form-submit" label-width="0">
        <div v-if="patent_way == 'pack' && !isEdit">
          <el-button type="primary" icon="el-icon-plus" circle class="mb-20" @click="addBtn"></el-button>
        </div>
        <el-button plain @click="goBack">
          返回
        </el-button>
        <el-button class="theme-back" type="primary" :loading="submitLoading" @click="submitForm">
          {{ isEdit && !isAddPackPatent ? '修改' : '发布' }}
        </el-button>
      </el-form-item>
    </el-form>

    <!-- 资产包专利列表 -->
    <template v-if="patent_way == 'pack' && isEdit && !isAddPackPatent">
      <div class="justify-between-center mb-30">
        <h2>专利列表</h2>
        <el-button type="primary" size="small" @click="addPackPatent">添加专利</el-button>
      </div>
      <PatentList :pack_id="$route.query.id" />
    </template>

    <el-dialog title="专利信息" :visible.sync="productInfoDialog" :lock-scroll="false">
      <el-descriptions :column="2">
        <el-descriptions-item label="专利名称" :span="2" labelClassName="d-strong" contentClassName="d-strong">{{
          productInfo.name }}
        </el-descriptions-item>
        <el-descriptions-item label="专利图片" :span="2">
          <div style="width: 200px;">
            <el-image :src="productInfo.path">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="专利号">{{ productInfo.ano }}</el-descriptions-item>
        <el-descriptions-item label="专利权人">{{ productInfo.patentee }}</el-descriptions-item>
        <el-descriptions-item label="专利类型">{{ productInfo.patent_type }}</el-descriptions-item>
        <el-descriptions-item label="领域类型">{{ productInfo.domain_type }}</el-descriptions-item>
        <el-descriptions-item label="有效期">{{ productInfo.valid_at }}年</el-descriptions-item>
        <el-descriptions-item label="法律状态">{{ productInfo.law_state }}</el-descriptions-item>
        <el-descriptions-item label="申请日">{{ productInfo.apply_at }}</el-descriptions-item>
        <el-descriptions-item label="失效日">{{ productInfo.expired_at }}</el-descriptions-item>
        <el-descriptions-item label="专利详情" :span="2">{{ productInfo.details }} </el-descriptions-item>
      </el-descriptions>
    </el-dialog>

  </div>
</template>
<script>
import * as TradingApi from '@/api/trading'
import PatentList from './sell_patent_list.vue'
import UploadImage from '@/components/UploadImage.vue'
const defaultForm = {
  patent_ano: '',
  patent_info: null,
  price: '',
  feat_type: '转让',
  loading: false
}
export default {
  name: 'MySellPatentForm',
  components: {
    UploadImage,
    PatentList
  },
  data () {
    var checkPrice = (rule, value, callback) => {
      const patter = new RegExp(/^[1-9]\d*$/)
      if (value && (!Number.isInteger(value) || !patter.test(value))) {
        return callback(new Error('请输入正整数'));
      }
      callback()
    };
    return {
      productInfoDialog: false,//商品详情信息框
      productInfo: {},//需要查看的商品详情信息
      patent_way: "single",//专利情况
      form: {
        name: '',
        price: '',
        path: '',
        details: '',
        patent_data: [Object.assign({}, defaultForm)],
      },
      checkPrice,
      rulesForm: {
        name: [{ required: true, message: '请输入资产包名称', trigger: 'blur' }],
        details: [{ required: true, message: '请输入资产包描述', trigger: 'blur' }],
        path: [{ required: true, message: '请上传资产包图片', trigger: 'blur' }],
        price: { validator: checkPrice, trigger: 'blur' },
        patent_ano: [{ required: true, message: '请输入专利号', trigger: 'blur' }],
        patent_info: [{ required: true, message: '请获取专利信息', trigger: 'change' }],
      },
      submitLoading: false,
      formData: new FormData(),
      isAddPackPatent: false//资产包添加商品
    }
  },
  watch: {
    '$route.query' () {
      this.changeFetchData()
    },
    productInfoDialog (newVal) {
      if (!newVal) {
        this.productInfo = {}
      }
    }
  },
  computed: {
    isEdit () {
      return this.$route.query.id ? true : false
    },
  },
  mounted () {
    if (this.$route.query.tab == 'Pack') {
      this.patent_way = 'pack'
    }
    this.changeFetchData()
  },
  methods: {
    // 切换单独卖出或者资产包卖出
    wayChange () {
      this.$refs.form.resetFields()
      this.form = this.$options.data().form
    },

    // 切换页面数据
    changeFetchData () {
      this.wayChange()
      if (this.isEdit) {
        this.patent_way = this.$route.query.way
        this.patent_way == "single" ? this.fetchPatent() : this.fetchPack()
      }
    },

    // 获取单个专利信息
    async fetchPatent () {
      try {
        const { data } = await TradingApi.fetchPatent(this.$route.query.id)
        this.form.patent_data[0]['patent_info'] = {}
        for (const key in data) {
          this.form.patent_data[0]['id'] = data.id
          this.form.patent_data[0]['patent_ano'] = data.ano
          this.form.patent_data[0]['state'] = data.state
          this.form.patent_data[0]['reject_text'] = data.reject_text
          if (key == 'price') {
            data[key] = data[key] == 0 ? '' : data[key]
          }
          if (this.form.patent_data[0].hasOwnProperty(key)) {
            this.form.patent_data[0][key] = data[key]
          } else {
            this.form.patent_data[0]['patent_info'][key] = data[key]
          }
        }
      } catch (error) {
        return false
      }
    },

    // 获取单个资产包信息
    async fetchPack () {
      try {
        const { data } = await TradingApi.fetchPack(this.$route.query.id)
        data['patent_data'] = []
        data['price'] = data['price'] == 0 ? '' : data['price']
        this.form = data
      } catch (error) {
        return false
      }
    },

    // 查询商品详情信息
    seekClick (item, ind) {
      this.$refs.form.validateField(`patent_data.${ind}.patent_ano`, async valid => {
        if (!valid) {
          item.loading = true
          try {
            let { data, code, msg } = await TradingApi.searchPatentInfo(item.patent_ano)
            if (code == 200) {
              item.patent_info = data.data
            } else {
              this.$message.error(msg);
            }
          } catch (error) { }
          item.loading = false
        }
      })
    },

    // 商品详情信息框
    lookTradingInfo (product_info) {
      this.productInfo = product_info
      this.productInfoDialog = true
    },

    // 添加
    addBtn () {
      this.form.patent_data.push(Object.assign({}, defaultForm))
    },

    // 删除
    delBtn (id, ind) {
      this.form.patent_data.splice(ind, 1)
      if (!this.form.patent_data.length) {
        this.addBtn()
      }
    },

    // 上传资产包图片
    changeUpload (val) {
      this.formData = val
    },

    // 资产包添加商品
    addPackPatent () {
      this.form.patent_data.push(Object.assign({ pack_id: this.$route.query.id }, defaultForm))
      this.isAddPackPatent = true
    },

    // 提交
    submitForm () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true

          let queryForm = JSON.parse(JSON.stringify(this.form))

          // 循环过滤掉专利详细信息
          if (queryForm.patent_data && queryForm.patent_data.length) {
            for (let i = 0; i < queryForm.patent_data.length; i++) {
              delete queryForm.patent_data[i].patent_info;
              if (!queryForm.patent_data[i]['price']) {
                queryForm.patent_data[i]['price'] = 0
              } else if (!queryForm.patent_data[i]) {
                delete queryForm.patent_data[i]
              }
            }
          }

          for (const key in queryForm) {
            if (key == 'price' && !queryForm['price']) {
              queryForm[key] = 0
            }
            if (key === 'path') continue
            if (key == 'patent_data' && this.patent_way == "pack" && !this.isAddPackPatent) {
              queryForm['patent_data'] = JSON.stringify(queryForm[key])
            }
            if (this.formData.has(key)) {
              this.formData.set(key, queryForm[key])
            } else {
              this.formData.append(key, queryForm[key])
            }
          }
          // 单个专利发布
          try {
            if (this.patent_way == "single" || this.isAddPackPatent) {
              var { code, msg } = (this.isEdit && !this.isAddPackPatent) ?
                await TradingApi.editPatent(queryForm.patent_data[0]) :
                await TradingApi.addPatent(queryForm.patent_data[0])
            } else {
              var { code, msg } = this.isEdit ?
                await TradingApi.editPack(this.formData) :
                await TradingApi.addPatentPack(this.formData)
            }
            if (code == 200) {
              if (!this.isEdit) {
                this.$store.dispatch('setIsTradingListReset', true)
              }
              this.goBack()
              this.formData = new FormData()
            } else {
              this.$message.error(msg);
            }
          } catch (error) { }
          this.submitLoading = false
        }
      });
    },
    goBack () {
      if (this.isAddPackPatent) {
        this.isAddPackPatent = false
        this.form.patent_data = []
      } else {
        if (this.patent_way == 'single') {
          this.$router.push({ name: 'MyTradingSell', params: { tab: 'Patent' } })
        } else {
          this.$router.push({ name: 'MyTradingSell', params: { tab: 'Pack' } })
        }
      }
    }
  },
}
</script>