/** 发布卖品表单 */
<template>
  <div class="my-sell-form">
    <el-tabs v-if="!isEdit" v-model="activeTab" @tab-click="handleClick">
      <el-tab-pane label="专利卖出" name="Patent"> </el-tab-pane>
      <el-tab-pane label="商标卖出" name="Trademark"> </el-tab-pane>
    </el-tabs>
    <PatentForm v-if="activeTab === 'Patent'" />
    <TrademarkForm v-if="activeTab === 'Trademark'" />
  </div>
</template>
<script>
import PatentForm from './components/sell_patent_form.vue'
import TrademarkForm from './components/sell_trademark_form.vue'
export default {
  name: 'MyTradingSellForm',
  components: {
    PatentForm,
    TrademarkForm
  },
  data () {
    return {
      activeTab: this.$route.query.tab == 'Pack' ? 'Patent' : this.$route.query.tab,
    }
  },

  computed: {
    isEdit () {
      return this.$route.query.id ? true : false
    },
  },
  methods: {
    handleClick (val) {
      this.$router.replace({ path: `/my/trading/product`, query: { tab: val.name } })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs {
  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }
  }

  .el-tabs__active-bar {
    height: 3px;
  }

  .el-tabs__item {
    font-size: 20px;
    font-weight: bold;
  }
}

::v-deep .el-form-item {
  &:last-child {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  &.form-item-dynamic>.el-form-item__content {
    display: flex;
    align-items: center;

    .el-button {
      height: 40px;
      margin-left: 15px;
    }
  }

  .el-input,
  .el-select {
    width: 100%;
  }

}

::v-deep .sell-trading-info {
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  color: #606266;
  height: 60px;
  padding: 0 15px;
  line-height: normal;

}

::v-deep .d-strong {
  font-weight: bold;
  font-size: 18px;
}
</style>