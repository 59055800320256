/** 发布卖品表单 */
<template>
  <div class="my-sell-form">
    <el-form ref="form" :model="form" :rules="rulesForm" hide-required-asterisk label-width="100px">
      <el-form-item v-if="!isEdit" label="商标情况">
        <el-select v-model="trademark_way" placeholder="请选择商标情况" @change="wayChange">
          <el-option label="单独商标卖出" value="single"> </el-option>
          <el-option label="商标资产包卖出" value="pack"> </el-option>
        </el-select>
      </el-form-item>

      <template v-if="trademark_way == 'pack'">
        <el-form-item prop="name" label="资产包名称">
          <span v-if="isAddPackTrademark">{{ form.name }}</span>
          <el-input v-else v-model="form.name" placeholder="请输入资产包名称"></el-input>
        </el-form-item>

        <el-form-item prop="price" label="价格 (元)">
          <span v-if="isAddPackTrademark">{{ form.price }}</span>
          <el-input v-else v-model.number="form.price" placeholder="不填写价格，默认显示面议"></el-input>
        </el-form-item>

        <el-form-item prop="path" label="资产包图片">
          <div v-if="isAddPackTrademark" style="width: 200px;height: 200px;">
            <el-image :src="$base + form.path"></el-image>
          </div>
          <UploadImage v-else v-model="form.path" @changeUpload="changeUpload" />
        </el-form-item>

        <el-form-item prop="details" label="资产包描述">
          <span v-if="isAddPackTrademark">{{ form.details }}</span>
          <el-input v-else type="textarea" v-model="form.details" placeholder="请输入资产包描述"></el-input>
        </el-form-item>

        <el-form-item v-if="form.state == 3" prop="reject_text" label="驳回原因">
          <span>{{ form.reject_text }}</span>
        </el-form-item>
      </template>

      <el-row>
        <el-col v-for="(item, index) in form.trademark_data" :key="index">
          <el-divider v-if="trademark_way == 'pack'"></el-divider>

          <el-form-item class="form-item-dynamic" :prop="'trademark_data.' + index + '.trademark_ano'"
            :label="'注册号' + (trademark_way == 'pack' && !isAddPackTrademark ? (index + 1) : '')"
            :rules="[{ required: true, message: '请输入注册号', trigger: 'blur' }]">
            <el-input v-model="item.trademark_ano" :disabled="isEdit && !isAddPackTrademark"
              placeholder="请输入注册号"></el-input>
            <el-button v-if="!isEdit || isAddPackTrademark" type="primary" @click="seekClick(item, index)"
              :loading="item.loading">商标查询</el-button>
            <el-button v-if="trademark_way == 'pack' && !isAddPackTrademark" type="danger"
              @click="delBtn(item.id, index)">删除</el-button>
          </el-form-item>

          <el-form-item :prop="'trademark_data.' + index + '.trademark_info'" label="商标信息"
            :rules="[{ required: true, message: '请获取商标信息', trigger: 'blur' }]">
            <div v-if="item.trademark_info && Object.keys(item.trademark_info).length"
              class="sell-trading-info justify-between-center">
              <div>
                <p>商标状态：{{ item.trademark_info.law_state }}</p>
                <p>国际分类：{{ item.trademark_info.domain_type }}</p>
              </div>
              <el-link type="primary" @click="lookTradingInfo(item.trademark_info)">调取成功，查看全部详情> </el-link>
            </div>
            <el-input v-else readonly placeholder="通过商标查询，获取商标信息"></el-input>
          </el-form-item>

          <el-form-item :prop="'trademark_data.' + index + '.price'" label="商标价格 (元)"
            :rules="[{ validator: checkPrice, trigger: 'blur' }]">
            <el-input v-model.number="item.price" placeholder="不填写价格，默认显示面议"></el-input>
          </el-form-item>

          <el-form-item prop="feat_type" label="合作类型">
            <el-select v-model="item.feat_type" placeholder="请选择合作类型">
              <el-option label="转让" value="转让"> </el-option>
              <el-option label="许可" value="许可"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="item.state == 3" prop="reject_text" label="驳回原因">
            <el-input type="textarea" v-model="item.reject_text" resize="none" :autosize="{ minRows: 1, maxRows: 3 }"
              readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item class="form-submit" label-width="0">
        <div v-if="trademark_way == 'pack' && !isEdit">
          <el-button type="primary" icon="el-icon-plus" circle class="mb-20" @click="addBtn"></el-button>
        </div>
        <el-button plain @click="goBack">
          返回
        </el-button>
        <el-button class="theme-back" type="primary" :loading="submitLoading" @click="submitForm">
          {{ isEdit && !isAddPackTrademark ? '修改' : '发布' }}
        </el-button>
      </el-form-item>
    </el-form>

    <!-- 资产包商标列表 -->
    <template v-if="trademark_way == 'pack' && isEdit && !isAddPackTrademark">
      <div class="justify-between-center mb-30">
        <h2>商标列表</h2>
        <el-button type="primary" size="small" @click="addPackTrademark">添加商标</el-button>
      </div>
      <TrademarkList :pack_id="$route.query.id" />
    </template>

    <el-dialog title="商标信息" :visible.sync="productInfoDialog" :lock-scroll="false">
      <el-descriptions :column="2">
        <el-descriptions-item label="商标名称" :span="2" labelClassName="d-strong" contentClassName="d-strong">{{
          productInfo.name }}
        </el-descriptions-item>
        <el-descriptions-item label="商标图片" :span="2">
          <el-image :src="productInfo.path">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="注册号">{{ productInfo.ano }}</el-descriptions-item>
        <el-descriptions-item label="国际分类">{{ productInfo.domain_type }}</el-descriptions-item>
        <el-descriptions-item label="类似群组">{{ productInfo.group }}</el-descriptions-item>
        <el-descriptions-item label="法律状态">{{ productInfo.law_state }}</el-descriptions-item>
        <el-descriptions-item label="有效日期">{{ productInfo.expired_at }}</el-descriptions-item>
        <el-descriptions-item label="申请日">{{ productInfo.apply_at }}</el-descriptions-item>
        <el-descriptions-item label="商标详情" :span="2">{{ productInfo.details }} </el-descriptions-item>
      </el-descriptions>
    </el-dialog>

  </div>
</template>
<script>
import * as TradingApi from '@/api/trading'
import TrademarkList from './sell_trademark_list.vue'
import UploadImage from '@/components/UploadImage.vue'
const defaultForm = {
  trademark_ano: '',
  trademark_info: null,
  price: '',
  feat_type: '转让',
  loading: false
}
export default {
  name: 'MySellTrademarkForm',
  components: {
    UploadImage,
    TrademarkList
  },
  data () {
    var checkPrice = (rule, value, callback) => {
      const patter = new RegExp(/^[1-9]\d*$/)
      if (value && (!Number.isInteger(value) || !patter.test(value))) {
        return callback(new Error('请输入正整数'));
      }
      callback()
    };
    return {
      productInfoDialog: false,//商品详情信息框
      productInfo: {},//需要查看的商品详情信息
      trademark_way: "single",//商标情况
      form: {
        name: '',
        price: '',
        path: '',
        details: '',
        trademark_data: [Object.assign({}, defaultForm)],
      },
      checkPrice,
      rulesForm: {
        name: [{ required: true, message: '请输入资产包名称', trigger: 'blur' }],
        details: [{ required: true, message: '请输入资产包描述', trigger: 'blur' }],
        path: [{ required: true, message: '请上传资产包图片', trigger: 'blur' }],
        price: { validator: checkPrice, trigger: 'blur' },
        trademark_ano: [{ required: true, message: '请输入注册号', trigger: 'blur' }],
        trademark_info: [{ required: true, message: '请获取商标信息', trigger: 'change' }],
      },
      submitLoading: false,
      formData: new FormData(),
      isAddPackTrademark: false//资产包添加商品
    }
  },
  watch: {
    '$route.query' () {
      this.changeFetchData()
    },
    productInfoDialog (newVal) {
      if (!newVal) {
        this.productInfo = {}
      }
    }
  },
  computed: {
    isEdit () {
      return this.$route.query.id ? true : false
    },
  },
  mounted () {
    this.changeFetchData()
  },
  methods: {
    // 切换单独卖出或者资产包卖出
    wayChange () {
      this.$refs.form.resetFields()
      this.form = this.$options.data().form
    },

    // 切换页面数据
    changeFetchData () {
      this.wayChange()
      if (this.isEdit) {
        this.trademark_way = this.$route.query.way
        this.trademark_way == "single" ? this.fetchTrademark() : this.fetchPack()
      }
    },

    // 获取单个商标信息
    async fetchTrademark () {
      try {
        const { data } = await TradingApi.fetchTrademark(this.$route.query.id)
        this.form.trademark_data[0]['trademark_info'] = {}
        for (const key in data) {
          this.form.trademark_data[0]['id'] = data.id
          this.form.trademark_data[0]['trademark_ano'] = data.ano
          this.form.trademark_data[0]['state'] = data.state
          this.form.trademark_data[0]['reject_text'] = data.reject_text
          if (key == 'price') {
            data[key] = data[key] == 0 ? '' : data[key]
          }
          if (this.form.trademark_data[0].hasOwnProperty(key)) {
            this.form.trademark_data[0][key] = data[key]
          } else {
            this.form.trademark_data[0]['trademark_info'][key] = data[key]
          }
        }
      } catch (error) {
        return false
      }
    },

    // 获取单个资产包信息
    async fetchPack () {
      try {
        const { data } = await TradingApi.fetchPack(this.$route.query.id)
        data['trademark_data'] = []
        data['price'] = data['price'] == 0 ? '' : data['price']
        this.form = data
      } catch (error) {
        return false
      }
    },

    // 查询商品详情信息
    seekClick (item, ind) {
      this.$refs.form.validateField(`trademark_data.${ind}.trademark_ano`, async valid => {
        if (!valid) {
          item.loading = true
          try {
            let { data, code, msg } = await TradingApi.searchTrademarkInfo(item.trademark_ano)
            if (code == 200) {
              item.trademark_info = data.data
              item.trademark_info['trademark_ano'] = item.trademark_ano
            } else {
              this.$message.error(msg);
            }
          } catch (error) { }
          item.loading = false
        }
      })
    },

    // 商品详情信息框
    lookTradingInfo (product_info) {
      this.productInfo = product_info
      this.productInfoDialog = true
    },

    // 添加
    addBtn () {
      this.form.trademark_data.push(Object.assign({}, defaultForm))
    },

    // 删除
    delBtn (id, ind) {
      this.form.trademark_data.splice(ind, 1)
      if (!this.form.trademark_data.length) {
        this.addBtn()
      }
    },

    // 上传资产包图片
    changeUpload (val) {
      this.formData = val
    },

    // 资产包添加商品
    addPackTrademark () {
      this.form.trademark_data.push(Object.assign({ pack_id: this.$route.query.id }, defaultForm))
      this.isAddPackTrademark = true
    },

    // 提交
    submitForm () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true

          let queryForm = JSON.parse(JSON.stringify(this.form))

          // 循环过滤掉商标详细信息
          if (queryForm.trademark_data && queryForm.trademark_data.length) {
            for (let i = 0; i < queryForm.trademark_data.length; i++) {
              delete queryForm.trademark_data[i].trademark_info;
              if (!queryForm.trademark_data[i]['price']) {
                queryForm.trademark_data[i]['price'] = 0
              } else if (!queryForm.trademark_data[i]) {
                delete queryForm.trademark_data[i]
              }
            }
          }

          for (const key in queryForm) {
            if (key == 'price' && !queryForm['price']) {
              queryForm[key] = 0
            }
            if (key === 'path') continue
            if (key == 'trademark_data' && this.trademark_way == "pack" && !this.isAddPackTrademark) {
              queryForm['trademark_data'] = JSON.stringify(queryForm[key])
            }
            if (this.formData.has(key)) {
              this.formData.set(key, queryForm[key])
            } else {
              this.formData.append(key, queryForm[key])
            }
          }
          // 单个商标发布
          try {
            if (this.trademark_way == "single" || this.isAddPackTrademark) {
              var { code, msg } = (this.isEdit && !this.isAddPackTrademark) ?
                await TradingApi.editTrademark(queryForm.trademark_data[0]) :
                await TradingApi.addTrademark(queryForm.trademark_data[0])
            } else {
              var { code, msg } = this.isEdit ?
                await TradingApi.editPack(this.formData) :
                await TradingApi.addTrademarkPack(this.formData)
            }
            if (code == 200) {
              if (!this.isEdit) {
                this.$store.dispatch('setIsTradingListReset', true)
              }
              this.goBack()
              this.formData = new FormData()
            } else {
              this.$message.error(msg);
            }
          } catch (error) { }
          this.submitLoading = false
        }
      });
    },
    goBack () {
      if (this.isAddPackTrademark) {
        this.isAddPackTrademark = false
        this.form.trademark_data = []
      } else {
        if (this.trademark_way == 'single') {
          this.$router.push({ name: 'MyTradingSell', params: { tab: 'Trademark' } })
        } else {
          this.$router.push({ name: 'MyTradingSell', params: { tab: 'Pack' } })
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs {
  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }
  }

  .el-tabs__active-bar {
    height: 3px;
  }

  .el-tabs__item {
    font-size: 20px;
    font-weight: bold;
  }
}

::v-deep .el-form-item {
  &:last-child {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  &.form-item-dynamic>.el-form-item__content {
    display: flex;
    align-items: center;

    .el-button {
      height: 40px;
      margin-left: 15px;
    }
  }

  .el-input,
  .el-select {
    width: 100%;
  }

}

.sell-trading-info {
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  color: #606266;
  height: 60px;
  padding: 0 15px;
  line-height: normal;

}

::v-deep .d-strong {
  font-weight: bold;
  font-size: 18px;
}
</style>