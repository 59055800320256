/** 商标卖出管理 */
<template>
  <div class="my-sell">
    <el-table :data="tableData" border :height="$isPc ? '630px' : '100%'" v-loading="loading">
      <el-table-column v-for="(item, index) in tableLabel" :key="index" :prop="item.value" :label="item.label"
        :width="item.width">
        <template slot-scope="scope">
          <template v-if="item.value === 'active'">
            <router-link
              :to="{ path: `/my/trading/product`, query: { id: scope.row.id, tab: 'Trademark', way: 'single' } }">
              <el-button type="primary" size="mini" class="mr-20">查看详情</el-button>
            </router-link>
            <el-popconfirm title="确定删除改商品吗？" @confirm="delTrademark(scope.$index, tableData, scope.row.id)">
              <el-button type="danger" size="mini" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>

          <template v-else>
            {{ scope.row[item.value] }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <Page :total="pageTotal" :pageSize="15" :resetPage="resetPage" @page="changePage" />
  </div>
</template>

<script>
import * as TradingApi from '@/api/trading'
import Page from '@/components/Page.vue'
export default {
  name: 'MySellTrademarkList',
  props: ['pack_id'],
  components: {
    Page
  },
  data () {
    return {
      tableLabel: [
        { label: '知产类型', value: 'trademark_type' },
        { label: '商标名称', value: 'name' },
        { label: '金额 (元)', value: 'price_text' },
        { label: '发布状态', value: 'state_text' },
        { label: '发布时间', value: 'release_at' },
        { label: '操作', value: 'active', width: '210px' }
      ],
      tableData: [],
      page: 1,
      pageTotal: 0,
      resetPage: false,//是否重置分页
      loading: false,
      finished: false, // 是否加载完成
    }
  },
  activated () {
    if (this.$store.getters.isTradingListReset) {
      this.page = 1
      this.resetPage = !this.resetPage
    }
    this.getTradingList()
    this.$store.dispatch('setIsTradingListReset', false)
  },
  mounted () {
    if (this.$route.name === 'MyTradingSellForm') {
      this.getTradingList()
    }
  },
  methods: {
    async getTradingList () {
      this.loading = true
      try {
        let { data } = await TradingApi.getTrademarkList({ page: this.page, pack_id: this.pack_id })
        data.data = data.data.map(v => {
          switch (v['state']) {
            case 1:
              v['state_text'] = '未审核'
              break;
            case 2:
              v['state_text'] = '审核通过'
              break;
            case 3:
              v['state_text'] = '审核未通过'
              break;
            case 4:
              v['state_text'] = '交易中'
              break;
            case 5:
              v['state_text'] = '交易成功'
              break;
            default:
              break;
          }
          v['price_text'] = v['price'] == 0 ? '面议' : v['price']
          return v
        })
        this.tableData = this.$isPc ? data.data : [...this.tableData, ...data.data]
        this.pageTotal = data.total
      } catch (error) { }
      this.loading = false
    },
    // 切换分页
    changePage (page) {
      this.page = page
      this.getTradingList()
    },
    // 删除订单
    async delTrademark (index, rows, id) {
      try {
        if (!this.$store.getters.userToken) return this.$message.warning('请先登录');
        let { code, msg } = await TradingApi.delTrademark(id)
        if (code == 200) {
          this.$message.success(msg);
          rows.splice(index, 1);
        } else {
          this.$message.error(msg);
        }
      } catch (error) { }
    }
  }
}
</script>