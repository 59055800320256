<template>
  <div class="upload-container">
    <el-upload v-show="imageUrl.length < 1" class="image-uploader" action drag :show-file-list="false"
      :before-upload="beforeUpload" :http-request="httpRequest" :headers="headers">
      <i class="el-icon-upload" />
      <div class="el-upload__text">
        将图片拖到此处，或<em>点击上传</em>
      </div>
    </el-upload>

    <span v-show="imageUrl.length < 1 && fileWidth && fileHeight" class="upload-advice">建议图片尺寸：{{ fileWidth + '*' +
      fileHeight
    }}</span>

    <div v-show="imageUrl.length > 1" class="image-preview">
      <div class="image-preview-wrapper">
        <img :src="tempUrl ? tempUrl : imageUrl">
        <div class="image-preview-action">
          <i class="el-icon-zoom-in" @click="dialogLook = true" />
          <i class="el-icon-delete" @click="rmImage" />
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogLook">
      <img width="100%" :src="tempUrl ? tempUrl : imageUrl" alt="">
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'Upload',
  props: {
    value: {
      type: String,
      default: ''
    },
    fileType: {
      type: Array,
      default: () => ['jpeg', 'png', 'jpg']
    },
    fileSize: {
      type: Number,
      default: 10
    },
    fileWidth: {
      type: Number,
      default: 0
    },
    fileHeight: {
      type: Number,
      default: 0
    },
    isCropper: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      dialogLook: false,
      dialogCropper: false,
      tempUrl: '',
      // 请求头
      headers: { 'Content-Type': 'multipart/form-data' },

      loading: false // 防止重复提交
    }
  },
  computed: {
    imageUrl () {
      return this.value ? this.$base + this.value : ''
    }
  },
  methods: {
    rmImage() {
      this.emitInput('')
      this.tempUrl = ''
    },
    emitInput (val) {
      this.$emit('input', val)
    },

    beforeUpload (file) {
      // 截取文件的后缀，判断文件类型
      const FileExt = file.name.replace(/.+\./, '').toLowerCase()
      // 如果文件类型不在允许上传的范围内
      if (!this.fileType.includes(FileExt)) {
        this.$message.warning('上传文件只能是' + this.fileType.join('、') + ' 格式! ')
        return false
      }

      // 计算文件的大小
      const isLtM = file.size / 1024 / 1024 < this.fileSize // 这里做文件大小限制
      if (!isLtM) {
        this.$message.warning(`上传文件大小不能超过${this.fileSize}MB!`)
        return false
      }
    },

    // 图片上传函数
    httpRequest (data) {
      this.tempUrl = URL.createObjectURL(data.file)
      this.emitInput(this.tempUrl)
      const formData = new FormData()
      formData.append('path', data.file)
      this.$emit('changeUpload', formData)
    },


  }
}
</script>

<style lang="scss" scoped>
.upload-container {
  width: 100%;
  max-width: 1000px;
  position: relative;

  .image-uploader {
    width: 100%;
    float: left;
  }

  .image-preview {
    min-width: 200px;
    height: 200px;
    position: relative;
    border: 1px dashed #d9d9d9;
    float: left;

    .image-preview-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .image-preview-action {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0, 0, 0, .5);
      transition: opacity .3s;
      cursor: pointer;
      text-align: center;
      line-height: 200px;

      i {
        font-size: 36px;
        margin: 0 10px;
      }
    }

    &:hover {
      .image-preview-action {
        opacity: 1;
      }
    }
  }

  .upload-advice {
    margin-top: -10px;
    color: var(--theme-defColor);
  }
}

::v-deep .cropper-content .cropper {
  width: auto;
  height: 500px;
}
</style>
